.containerHeader {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/img/header.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    mask-image: linear-gradient(to top, transparent -10%, #FFFFFF 25%);
}
.contentHeader {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    gap: 20px;
    line-height: 200%;
    overflow: hidden;
}

.contentHeader span:nth-child(1) {
    padding: 5%;
}

.logoImaculada {
    position: absolute;
    top: 4%;
    left: 5%;
    width: 100px;
}

.btnDefault {
    padding: 12px 30px;
    border-radius: 20px;
    border: none;
    background-color: #216090;
    color: #F1F1F3;
    font-weight: bold;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.btnDefault:hover {
    background-color: #00C4B6;
}

.btnPreMatricula {
    position: absolute;
    top: 5%;
    right: 3%;
}

.btnMatriculas2024 {
    display: flex;
    gap: 20px;
    margin: 10px 0;
}

.imgAluno {
    height: 100vh;
    width: 50%;
    position: relative;
}

#matriculasAbertas {
    position: absolute;
    bottom: 0;
    left: 0;
    width: clamp(400px, 600px, 800px);
    border-radius: 50% 0 0 0;
}

@media (max-width: 1000px) {

    .containerHeader {
        mask-image: linear-gradient(to top, transparent -10%, #FFFFFF 10%)
    }

    .contentHeader {
        display: block;
    }

    .contentHeader span:nth-child(1) {
        position: absolute;
        padding: 20px;
        margin-top: 190px;
        width: 100%;
        height: 170px;
        box-sizing: border-box;
        z-index: 10;
    }

    .btnPreMatricula {
        display: none;
    }

    .imgAluno {
        position: absolute;
        margin-top: 300px;
        height: 500px;
        width: 100%;
    }
    #matriculasAbertas {
        position: absolute;
        width: 100%;
        max-width: 400px;
        max-height: 400px;
        bottom: 0;
    }
}